import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import {ContrastContext} from '../../helpers/context';
import SEO from '../../components/common/SEO';
import {Hero, H1, H6, P, LinkDark} from '../../components/common/Typography';
import {breakpoints, colors, ContentContainer, fontWeights} from '../../styles/theme';
import CircleBackground from '../../components/about/CircleBackground';


/*
 * Constants
 */
// eslint-disable-next-line i18next/no-literal-string
const defaultEmail = 'hello@switchpayments.com';
const defaultText = <Trans>Our team will get back to you shortly.</Trans>;


/*
 * Private Elements
 */
const ThankYouContainer = styled.div`
    background-color: ${colors.black};
    min-height: 100vh;
    position: relative;
    display: flex;
`;

const ThankYouCircleContainer = styled.div`
    width: 100%;

    ${CircleBackground} {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    ${ContentContainer} {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding-left: 25px;
        padding-right: 25px;
        height: 100%;
        color: ${colors.white};

        .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            text-align: center;

            .message {
                margin-top: 50%;

                ${H6} {
                    font-weight: ${fontWeights.regular};
                    margin-top: 20px;
                }
            }

            .questions {
                margin-bottom: 10px;
            }
        }
    }

    @media not all and (min-width: ${breakpoints.lg}) {
        ${ContentContainer} {
            ${Hero} {
                font-size: 34px;
                line-height: 44px;
                font-weight: ${fontWeights.semiBold};
            }
        }
    }
`;


/*
 * Public Elements
 */
const ThankYouPage = ({location}) => {
    const setContrast = useContext(ContrastContext);
    setContrast(false);
    const {t} = useTranslation();
    const contactEmail = location.state?.contactEmail || defaultEmail;
    const text = location.state?.customText || defaultText;

    return (
        <>
            <SEO title={t('Thank You')} />

            <ThankYouContainer>
                <ThankYouCircleContainer>
                    <CircleBackground />

                    <ContentContainer>
                        <div className="content">
                            <div className="message">
                                <H1><Trans>Thank you for contacting us.</Trans></H1>
                                <H6>{text}</H6>
                            </div>

                            <div className="questions">
                                <P>
                                    <Trans>
                                        For questions please reach out to
                                        {' '}
                                        <LinkDark href={`mailto:${contactEmail}`}>{contactEmail}</LinkDark>
                                    </Trans>
                                </P>
                            </div>
                        </div>
                    </ContentContainer>
                </ThankYouCircleContainer>
            </ThankYouContainer>
        </>
    );
};

ThankYouPage.propTypes = {
    location: PropTypes.object.isRequired,
};


/*
 * Exports
 */
export default ThankYouPage;
